<template>
    <DynamicForm ref="form" @submit="onSubmit" :configs="configs" class="tw-w-1/2 tw-mx-auto tw-my-3" />
</template>

<script>
import DynamicForm from "@/components/DynamicForm";

const formSchemasMock = [
    {
        type: "dropdown",
        label: "integer",
        mandatory: true,
        multi: true,
        options: [1, 2, 3, 4, 5, 6, 7],
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "input",
        multi: true,
        mandatory: true,
        grid: true,
        label: "username",
        validation: {
            validation_regex: ".+@.+\..+",
        },
    },
    {
        type: "file_upload",
        label: "profile image",
        mandatory: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "numeric",
        label: "age",
        mandatory: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "numeric",
        label: "age",
        mandatory: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "date",
        mandatory: true,
        label: "username",
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "checkbox",
        label: "gradient",
        options: ["red", "gray", "orange"],
        mandatory: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "radio",
        label: "colors",
        options: ["red", "gray", "orange"],
        mandatory: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "textarea",
        mandatory: true,
        label: "bio",
        multi: true,
        validation: {
            validation_regex: "",
        },
    },
    {
        type: "block",
        mandatory: true,
        label: "block",
        multi: true,
        validation: {
            validation_regex: "",
        },
    },
];

export default {
    components: {DynamicForm},
    data: () => ({
        configs: formSchemasMock,
    }),
    methods: {
        onSubmit(models) {
            console.log(this.$refs.form.validate(), models);
        },
    },
};
</script>
